const productTypeDescriptionData = [
    {
        label: "Sim",
        value: "true",
    },
    {
        label: "Não",
        value: "false",
    },
    {
        label: "Ambos",
        value: "both",
    }
];

export default productTypeDescriptionData;